import React from "react";
import {
  Container,
  Grid,
  GridItem,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { Footer, MetaTags, Navbar } from "../components";
import WorksJson from "../data/works.json";
import PortfolioTile from "../components/PortfolioTile";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { FaArrowLeft } from "react-icons/fa";

export const Works: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <div>
      <MetaTags />
      <Navbar />
      <Container py={60} maxW="container.xl" mx="auto" w="100%">
        <Grid
          templateColumns={{ md: `repeat(3, 1fr)`, sm: `repeat(1, 1fr)` }}
          gap={4}
        >
          {WorksJson.map((work, index) => (
            <GridItem key={index} onClick={onOpen}>
              <PortfolioTile
                title={work.title}
                description={work.description}
                tags={work.tags}
                enlarged
              />
            </GridItem>
          ))}
        </Grid>
      </Container>
      <Footer />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={`xl`}>
        <DrawerOverlay />
        <DrawerContent bgColor={`primary.400`}>
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>

          <DrawerBody>
            <i>More details available soon.</i>
          </DrawerBody>

          <DrawerFooter justifyContent={`center`}>
            <Button
              leftIcon={<FaArrowLeft />}
              variant="outline"
              mr={3}
              onClick={onClose}
              borderRadius={0}
            >
              RETURN
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};
