import { Container, Text, Stack } from "@chakra-ui/react";
import { FiCoffee, FiCode, FiHeart } from "react-icons/fi";
import React from "react";
import { OutreachButtons } from "./OutreachButtons";

const Footer = () => {
  return (
    <Container maxW="container.xl" mx="auto" w="100%" mt={24} centerContent>
      <OutreachButtons />
      <Text fontSize={[50, 60, 80]} fontWeight="700" textAlign="center">
        <a href="mailto:hello@humza.io">hello@humza.io</a>
      </Text>
      <Stack
        direction="row"
        fontSize={[24, 36, 36]}
        mb="12"
        spacing={4}
        alignItems={`center`}
      >
        <FiCode />
        <Text fontSize={`xl`}>with</Text>
        <FiCoffee />
        <Text fontSize={`xl`}>and</Text>
        <FiHeart />
      </Stack>
    </Container>
  );
};

export default Footer;
