import React, { useEffect, useState } from "react";
import {
  ContentPanel,
  OrbitAnimation,
  Typewriter,
  MovieTile,
} from "../components";
import {
  Grid,
  GridItem,
  Box,
  Text,
  Icon,
  Stack,
  Link,
  Button,
  Tooltip,
  Container,
} from "@chakra-ui/react";
import {
  FiTwitter,
  FiMail,
  FiArrowRight,
  FiBookOpen,
  FiBook,
  FiRepeat,
} from "react-icons/fi";
import { HiBookOpen } from "react-icons/hi";
import moment from "moment-timezone";
import PortfolioTile from "../components/PortfolioTile";
import { Link as RouterLink } from "react-router-dom";
import WorksJson from "../data/works.json";
import "./app.css";

const worksPanel = () => (
  <div>
    <Box fontSize="md" textTransform="uppercase" pb={6}>
      PROMINENT WORKS
    </Box>
    <Stack
      direction="column"
      gap={4}
      maxHeight={`400px`}
      overflow={`auto`}
      px={4}
      className="feed-scrollbar"
    >
      {WorksJson.map((work) => (
        <RouterLink to={`/works`}>
          <PortfolioTile
            title={work.title}
            description={
              work.description.length > 100
                ? work.description.substring(0, 100)
                : work.description
            }
            tags={work.tags}
          />
        </RouterLink>
      ))}
    </Stack>
    <Stack
      direction={`row`}
      gap={4}
      alignItems={`center`}
      justifyContent={`center`}
      pt={6}
    >
      <RouterLink to={`/works`}>
        <Button
          rightIcon={<FiRepeat />}
          variant="outline"
          borderWidth={`1px`}
          borderStyle={`solid`}
          borderColor={`white`}
        >
          Browse All
        </Button>
      </RouterLink>

      <RouterLink to={`/resume`}>
        <Button
          rightIcon={<HiBookOpen />}
          variant="outline"
          borderWidth={`1px`}
          borderStyle={`solid`}
          borderColor={`white`}
        >
          View Resume
        </Button>
      </RouterLink>
    </Stack>
  </div>
);

const Feed = () => {
  const [time, setTime] = useState(
    moment().tz("America/Montreal").format("HH:mm")
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(moment().tz("America/Montreal").format("HH:mm"));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Grid
      templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]}
      gap={4}
      p={4}
      w="100%"
    >
      <GridItem>
        {/** ABOUT */}
        <ContentPanel>
          <Box fontSize="md" textTransform="uppercase" pb={12}>
            ABOUT
          </Box>
          <Box>
            👋 Hello — I'm Humza Khan, a Product Engineer / Full Stack Developer
            based in Montreal, Canada 🇨🇦. I prefer to use the term Product
            Engineer because I operate from the "why" of the business objectives
            when translating requirements into code, followed by the "how".
          </Box>
          <Box pt={6}>
            Outside of work, I have profound interests in studying cosmology,
            philosophy and listening to good techno & deep house music.
          </Box>
        </ContentPanel>
        {/** /ABOUT */}

        {/** WORKS */}
        <Box display={["block", "block", "none"]}>
          <ContentPanel>{worksPanel()}</ContentPanel>
        </Box>
        {/** /WORKS */}

        {/** ALPHA */}
        {/* <ContentPanel>
          <Box fontSize="md" textTransform="uppercase" pb={12}>
            ALPHA
          </Box>
        </ContentPanel> */}
        {/** /ALPHA */}

        {/** Quote */}
        <ContentPanel>
          <Grid templateColumns={{ base: "1fr", md: "1fr 3fr" }}>
            <GridItem>
              <Container centerContent>
                <OrbitAnimation />
              </Container>
            </GridItem>
            <GridItem alignItems="center" justifyContent="center">
              <Box mt={5}>
                <Text>Do not go gentle into that good night,</Text>
                <Text>Old age should burn and rave at close of day;</Text>
                <Text>Rage, rage against the dying of the light.</Text>
              </Box>
            </GridItem>
          </Grid>
        </ContentPanel>
        {/** /Quote */}

        {/** Movies */}
        <ContentPanel>
          <Box fontSize="md" textTransform="uppercase" pb={6}>
            FAVORITE MOVIES
          </Box>
          <Box
            position="relative"
            width="100%"
            height="300px"
            top={`50px`}
            left={["0px", "40px", "50px"]}
          >
            <Box
              position="absolute"
              left={0}
              zIndex={10}
              transform={`rotate(-10deg)`}
              _hover={{ zIndex: 1000 }}
            >
              <MovieTile
                url="https://www.imdb.com/title/tt0816692"
                cover="https://i.imgur.com/21pPUdl.jpg"
                title="Interstellar"
              />
            </Box>
            <Box position="absolute" left={`20%`} zIndex={20} top={`-30px`}>
              <MovieTile
                url="https://www.imdb.com/title/tt0119217"
                cover="https://i.imgur.com/sjR4SbA.jpg"
                title="Good Will Hunting"
                main={true}
              />
            </Box>
            <Box
              position="absolute"
              left={`45%`}
              zIndex={5}
              transform={`rotate(10deg)`}
              _hover={{ zIndex: 1000 }}
            >
              <MovieTile
                url="https://www.imdb.com/title/tt0338013"
                cover="https://i.imgur.com/6FlQjv2.jpg"
                title="Eternal Sunshine of the spotless mind"
              />
            </Box>
          </Box>
          <Text textAlign={`center`} mt={6}>
            and a bunch more!
          </Text>
        </ContentPanel>
        {/** /Movies */}

        {/** Reading */}
        <ContentPanel>
          <Box fontSize="md" textTransform="uppercase" pb={12}>
            Readings
          </Box>

          <Stack direction="column" gap={6}>
            <Grid templateColumns={["1fr", "1fr 4fr"]}>
              <GridItem fontSize={`4xl`}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  h={`100%`}
                  pb={[4, 0, 0]}
                >
                  <FiBookOpen />
                </Box>
              </GridItem>
              <GridItem textAlign={["center", "center", "left"]}>
                I'm currently reading{" "}
                <Link
                  href="https://www.goodreads.com/book/show/840.The_Design_of_Everyday_Things"
                  textDecor={`underline`}
                  isExternal
                >
                  Design of Everyday Things
                </Link>{" "}
                by Donald A. Norman.
              </GridItem>
            </Grid>
            <Grid templateColumns={["1fr", "1fr 4fr"]}>
              <GridItem fontSize={`4xl`}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  h={`100%`}
                  pb={[4, 0, 0]}
                >
                  <FiBook />
                </Box>
              </GridItem>
              <GridItem textAlign={["center", "center", "left"]}>
                <Text>
                  Recently finished reading{" "}
                  <Link
                    href="https://www.goodreads.com/book/show/22892322-the-universe-in-your-hand"
                    textDecor={`underline`}
                    isExternal
                  >
                    The Universe in Your Hand: A Journey Through Space, Time,
                    and Beyond
                  </Link>{" "}
                  by Christophe Galfard
                </Text>
              </GridItem>
            </Grid>
          </Stack>
        </ContentPanel>
        {/** /Reading */}
      </GridItem>

      <GridItem>
        {/** WORKS */}
        <Box display={["none", "none", "block"]}>
          <ContentPanel>{worksPanel()}</ContentPanel>
        </Box>
        {/** /WORKS */}

        {/** BLOG */}
        <ContentPanel>
          <Box fontSize="md" textTransform="uppercase" pb={12}>
            Published Content
          </Box>
          <Box
            textTransform="uppercase"
            fontSize="sm"
            fontWeight={500}
            textAlign="center"
          >
            <span>
              [
              <Typewriter
                text="LIVE FEED UNDER DEVELOPMENT..."
                delay={200}
                infinite
              />
              ]
            </span>
          </Box>
        </ContentPanel>
        {/** /BLOG */}

        {/** CLOCK */}
        <ContentPanel>
          <Box fontSize="md" textTransform="uppercase" pb={6}>
            Local Time 🇨🇦
          </Box>

          <Box
            w="100%"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Text fontSize="6xl" fontWeight="800">
              {time}
            </Text>
            <Text fontSize="3xl">Montreal, Quebec</Text>
            <Text fontSize="lg" mt={6}>
              {moment().format("MMM D, YYYY")}
            </Text>
          </Box>
        </ContentPanel>
        {/** /CLOCK */}

        {/** CONTACT */}
        <ContentPanel>
          <Box fontSize="md" textTransform="uppercase" pb={12}>
            Contact
          </Box>
          <Box>
            <Grid templateColumns={{ base: "1fr 1fr" }}>
              <GridItem
                textAlign="center"
                onClick={() => {
                  window.location.href = "https://twitter.com/humzaio";
                }}
              >
                <Box textAlign="center" _hover={{ cursor: "pointer" }}>
                  <Icon as={FiTwitter} fontSize="4rem" />
                </Box>
                <Stack
                  direction="row"
                  fontSize="lg"
                  mt={4}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text>DMs</Text>
                  <FiArrowRight />
                </Stack>
              </GridItem>
              <GridItem
                onClick={() => {
                  window.location.href = "mailto:hello@humza.io";
                }}
              >
                <Box textAlign="center" _hover={{ cursor: "pointer" }}>
                  <Icon as={FiMail} fontSize="4rem" />
                </Box>
                <Stack
                  direction="row"
                  fontSize="lg"
                  mt={4}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text>Email</Text>
                  <FiArrowRight />
                </Stack>
              </GridItem>
            </Grid>
          </Box>
        </ContentPanel>
        {/** /CONTACT */}
      </GridItem>
    </Grid>
  );
};

export default Feed;
