import { Box, Text, Button } from "@chakra-ui/react";
import { InterstellarAnimation, PlanetsAnimation } from "./components";
import { Link as RouterLink } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

export const NotFound = () => {
  return (
    <Box height={`100vh`} width={`100vw`} overflow={`hidden`}>
      <Box
        position={`absolute`}
        textAlign="center"
        fontSize="xl"
        display={`flex`}
        justifyContent={`center`}
        width={`100%`}
        height={`100%`}
        overflow={`hidden`}
      >
        <InterstellarAnimation />
        <Box w={`80vw`} h={`auto`}>
          <PlanetsAnimation />
        </Box>
      </Box>
      <Box zIndex={100} textAlign={`center`} pt={24}>
        <Text fontSize={68} fontWeight={900}>
          404
        </Text>
        <Text fontSize={32} fontWeight={700}>
          Nothing's present here.
        </Text>
        <Text fontSize={32} fontWeight={700}>
          Except you & the infinite void of space.
        </Text>
        <RouterLink to={`/`}>
          <Button
            variant={`outline`}
            zIndex={1000}
            leftIcon={<FaArrowLeft />}
            mt={8}
          >
            Return to Civilization
          </Button>
        </RouterLink>
      </Box>
    </Box>
  );
};
