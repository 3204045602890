import React from "react";
import ReactHelmet from "react-helmet";

export const MetaTags: React.FC = () => {
  return (
    <ReactHelmet>
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://humza.io/" />
      <meta property="og:title" content="Humza Khan" />
      <meta
        property="og:description"
        content="Product Engineer, Solutions Architect based in Montreal, QC."
      />
      <meta property="og:image" content="https://i.imgur.com/rrBxTmK.png" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://humza.io/" />
      <meta property="twitter:title" content="Humza Khan" />
      <meta
        property="twitter:description"
        content="Product Engineer, Solutions Architect based in Montreal, QC."
      />
      <meta
        property="og:image"
        content="https://i.imgur.com/rrBxTmK.png"
      ></meta>
    </ReactHelmet>
  );
};
