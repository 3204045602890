import React, { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Homepage, Resume, Works } from "./pages";
import { NotFound } from "./NotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
    errorElement: <NotFound />,
  },
  {
    path: "/works",
    element: <Works />,
  },
  {
    path: "/resume",
    element: <Resume />,
  },
]);

export const Home = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const ref = queryParams.get("ref");
    if (ref === "fs" || ref === "swe") {
      localStorage.setItem("version", ref);
    }
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
};
