import React, { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

interface ContentPanelProps {
  children: ReactNode;
}

const ContentPanel = ({ children }: ContentPanelProps) => {
  return (
    <Box
      bgColor="primary.400"
      p={8}
      borderRadius={"xl"}
      fontWeight={600}
      fontSize={"lg"}
      position="relative"
      mb={4}
      w={["100%", "100%", "100%"]}
    >
      {children}
    </Box>
  );
};

export default ContentPanel;
