import React from "react";
import { Box, Badge, Stack, Wrap, WrapItem } from "@chakra-ui/react";
import { FiArrowUpRight } from "react-icons/fi";

interface PortfolioTileProps {
  title?: string;
  description?: string;
  tags: string[];
  enlarged?: boolean;
}

const PortfolioTile = (props: PortfolioTileProps) => {
  return (
    <Box
      bgColor={props.enlarged ? `primary.400` : `primary.700`}
      borderRadius={8}
      position={`relative`}
      transition="transform 0.3s"
      _hover={{ cursor: "pointer", transform: "scale(0.95)" }}
      minHeight={props.enlarged ? `100%` : `auto`}
    >
      <Box
        position={`absolute`}
        right={props.enlarged ? `25px` : `8px`}
        top={props.enlarged ? `25px` : `8px`}
      >
        <FiArrowUpRight />
      </Box>
      <Box
        p={props.enlarged ? 6 : 2}
        textAlign={`center`}
        textTransform={`uppercase`}
        fontWeight={props.enlarged ? `700` : `600`}
        fontSize={`md`}
      >
        {props.title}
      </Box>
      <Box
        px={props.enlarged ? 10 : 4}
        textAlign={`center`}
        fontWeight={500}
        fontSize={`md`}
      >
        {props.description}
      </Box>
      <Box py={props.enlarged ? 6 : 4} fontWeight={500} fontSize={`sm`}>
        <Wrap spacing={2} justify={`center`}>
          {props.tags.map((tag) => (
            <WrapItem>
              <Badge colorScheme={`purple`}>{tag}</Badge>
            </WrapItem>
          ))}
        </Wrap>
      </Box>
    </Box>
  );
};

export default PortfolioTile;
