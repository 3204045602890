import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import {
  Flex,
  Text,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Link,
} from "@chakra-ui/react";
import { HamburgerIcon, StarIcon } from "@chakra-ui/icons";
import {
  FaGithub,
  FaTwitter,
  FaStreetView,
  FaMailBulk,
  FaRegNewspaper as IconResume,
} from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";

function Navbar() {
  const [time, setTime] = useState(
    moment().tz("America/Montreal").format("HH:mm")
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(moment().tz("America/Montreal").format("HH:mm"));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      paddingX={6}
      paddingY={4}
      zIndex={10}
      position={`fixed`}
      w={`100vw`}
    >
      <Flex align="center">
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            variant="outline"
          />
          <MenuList bgColor={`primary.400`} shadow={`none`}>
            <MenuItem
              icon={<Icon as={FaStreetView} w={5} h={5} />}
              as={RouterLink}
              to={`/`}
              fontWeight={600}
              bgColor={`primary.400`}
              _hover={{ bg: "primary.500" }}
            >
              Personal
            </MenuItem>
            <MenuDivider />

            <MenuItem
              icon={<Icon as={StarIcon} w={5} h={5} />}
              as={RouterLink}
              to={`/works`}
              fontWeight={600}
              bgColor={`primary.400`}
              _hover={{ bg: "primary.500" }}
            >
              Works
            </MenuItem>

            <MenuItem
              icon={<Icon as={IconResume} w={5} h={5} />}
              as={RouterLink}
              to={`/resume`}
              fontWeight={600}
              bgColor={`primary.400`}
              _hover={{ bg: "primary.500" }}
            >
              Resume
            </MenuItem>

            <MenuItem
              icon={<Icon as={FaGithub} w={5} h={5} />}
              fontWeight={600}
              bgColor={`primary.400`}
              _hover={{ bg: "primary.500" }}
            >
              <Link href={`https://github.com/humzakhan`} isExternal>
                GitHub
              </Link>
            </MenuItem>

            <MenuItem
              icon={<Icon as={FaTwitter} w={5} h={5} />}
              fontWeight={600}
              bgColor={`primary.400`}
              _hover={{ bg: "primary.500" }}
            >
              <Link href={`https://twitter.com/humzaio`} isExternal>
                Twitter
              </Link>
            </MenuItem>

            <MenuItem
              icon={<Icon as={FaMailBulk} w={5} h={5} />}
              fontWeight={600}
              bgColor={`primary.400`}
              _hover={{ bg: "primary.500" }}
            >
              <Link href={`mailto:hello@humza.io`} isExternal>
                Get in touch
              </Link>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Text fontSize="3xl" fontWeight="bold">
        <Link to={`/`} as={RouterLink} _hover={{ textDecor: "none" }}>
          Humza
        </Link>
      </Text>
      <Text fontSize="sm" fontWeight={500} display={["none", "none", "block"]}>
        MONTREAL ({time})
      </Text>

      <Text fontSize="sm" fontWeight={500} display={["block", "block", "none"]}>
        MTL ({time})
      </Text>
    </Flex>
  );
}

export default Navbar;
