import React, { useEffect } from "react";
import { MetaTags, Navbar } from "../components";
import {
  Container,
  Grid,
  Text,
  Flex,
  Box,
  Stack,
  List,
  ListItem,
  ListIcon,
  GridItem,
  Link,
  SimpleGrid,
} from "@chakra-ui/react";

import { GoLocation as IconLocation, GoMail as IconMail } from "react-icons/go";
import {
  FiLinkedin as IconLinkedIn,
  FiGithub as IconGithub,
} from "react-icons/fi";
import {
  HiOutlineTranslate as IconLanguage,
  HiChevronRight as IconRight,
  HiOutlineArrowRight as IconSublist,
} from "react-icons/hi";

import {
  FaAmazon as IconAmazon,
  FaUserGraduate as IconGraduation,
  FaPenSquare as IconWriting,
} from "react-icons/fa";

interface HeadlineProps {
  text: string;
}

export const Resume: React.FC = () => {
  enum ResumeVersion {
    FS = "fs",
    SWE = "swe",
  }

  const fullStackResumeLink =
    "https://drive.google.com/drive/folders/13v5YkARN8P1yEX5CDKtvZV0Oynw4vLB6?usp=sharing";
  const backendResumeLink =
    "https://drive.google.com/drive/folders/1k8uv-z8omP6DWfOYOjJcsH2S_VcuGWz9?usp=drive_link";

  const [version, setVersion] = React.useState(ResumeVersion.SWE);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const ref = queryParams.get("ref")?.toLowerCase();
    if (ref && Object.values(ResumeVersion).includes(ref as ResumeVersion)) {
      setVersion(ref as ResumeVersion);
      localStorage.setItem("version", ref);
    } else {
      const storedVersion = localStorage.getItem("version");
      setVersion(
        storedVersion &&
          Object.values(ResumeVersion).includes(storedVersion as ResumeVersion)
          ? (storedVersion as ResumeVersion)
          : ResumeVersion.SWE
      );
    }
    window.scrollTo(0, 0);
  }, []);

  const FullStackTechStack = () => (
    <Box>
      <Box my={6}>
        <Text fontWeight={`700`} textTransform={`uppercase`}>
          back-end development
        </Text>
        <Text>
          TypeScript, Express, Node, Scala, Python, Docker, Elasticsearch,
          Redis, Kafka
        </Text>
      </Box>
      <Box my={6}>
        <Text fontWeight={`700`} textTransform={`uppercase`}>
          front-end development
        </Text>
        <Text>
          React, Redux, Mobx, Blueprint, Tailwind, ChakraUI, TypeScript
        </Text>
      </Box>
      <Box my={6}>
        <Text fontWeight={`700`} textTransform={`uppercase`}>
          database systems
        </Text>
        <Text>
          Postgres, MariaDB, MongoDB, SQL Server (v 2012 & 2014 mostly)
        </Text>
      </Box>
    </Box>
  );

  const BackendTechStack = () => (
    <Box>
      <Box my={6}>
        <Text fontWeight={`700`} textTransform={`uppercase`}>
          back-end development
        </Text>
        <Box my={3}>
          <Text fontWeight={`bold`}>Currently working with:</Text>
          <Text>
            Java, Scala, Kafka, Elasticsearch, GraphQL, Slick, Spring Boot,
            Python, Terraform, Docker, Redis
          </Text>
        </Box>
        <Box my={3}>
          <Text fontWeight={`bold`}>Spent significant time working with:</Text>
          <Text>
            Express, TypeScript, Cypress, C# .NET Framework & Core, EF Core,
            Ansible, Apache Airflow, Kudu, Impala
          </Text>
        </Box>
      </Box>
      <Box my={6}>
        <Text fontWeight={`700`} textTransform={`uppercase`}>
          front-end development
        </Text>
        <Text>
          React, Redux, Mobx, Blueprint, Tailwind, ChakraUI, TypeScript
        </Text>
      </Box>
      <Box my={6}>
        <Text fontWeight={`700`} textTransform={`uppercase`}>
          database systems
        </Text>
        <Text>
          Postgres, MariaDB, MongoDB, SQL Server (v 2012 & 2014 mostly)
        </Text>
      </Box>
    </Box>
  );

  const Headline: React.FC<HeadlineProps> = ({ text }) => {
    return (
      <Text
        as="h1"
        textTransform="uppercase"
        borderBottom="2px solid"
        borderColor="currentColor"
        paddingBottom="0.5rem"
        mb="0.5rem"
        fontSize="xl"
        fontWeight="bold"
      >
        {text}
      </Text>
    );
  };

  return (
    <div>
      <MetaTags />
      <Navbar />
      <Container py={40} maxW="container.xl" mx="auto" w="100%">
        <Text align={`center`} fontSize={`3xl`} fontWeight={`700`}>
          {version === ResumeVersion.FS
            ? "Senior Full Stack Product Engineer"
            : "Senior Software Engineer, Distributed Systems"}
        </Text>
        <Box textAlign={`center`} mt={4}>
          <Link
            href={
              version === ResumeVersion.FS
                ? fullStackResumeLink
                : backendResumeLink
            }
            textDecor={`underline`}
            isExternal
          >
            Download one-page light-themed PDF version
          </Link>
        </Box>

        <Grid templateColumns={["1fr", "repeat(12, 1fr)"]} gap={8} mt={28}>
          <GridItem colSpan={[12, 3]}>
            <Box mb={12}>
              <Headline text="Contact" />
              <List mt={4} fontSize={`lg`} spacing={4}>
                <ListItem>
                  <ListIcon as={IconLocation} />
                  Montreal, QC
                </ListItem>
                <ListItem>
                  <ListIcon as={IconMail} />
                  <Link
                    fontWeight={`600`}
                    textDecor={`underline`}
                    href="mailto:hello@humza.io"
                    isExternal
                  >
                    hello@humza.io
                  </Link>
                </ListItem>
                <ListItem>
                  <ListIcon as={IconLinkedIn} />
                  <Link
                    fontWeight={`600`}
                    textDecor={`underline`}
                    href="https://www.linkedin.com/in/humzakhaan/"
                    isExternal
                  >
                    LinkedIn
                  </Link>
                </ListItem>
                <ListItem>
                  <ListIcon as={IconGithub} />
                  <Link
                    fontWeight={`600`}
                    textDecor={`underline`}
                    href="https://github.com/humzakhan"
                    isExternal
                  >
                    GitHub
                  </Link>
                </ListItem>
              </List>
            </Box>

            <Box mb={12} display={["none", "block"]}>
              <Headline text="Languages" />
              <List mt={4} fontSize={`lg`} spacing={4}>
                <ListItem>
                  <ListIcon as={IconLanguage} />
                  English (Fluent)
                </ListItem>

                <ListItem>
                  <ListIcon as={IconLanguage} />
                  French (Basic)
                </ListItem>

                <ListItem>
                  <ListIcon as={IconLanguage} />
                  Urdu (Native)
                </ListItem>
              </List>
            </Box>

            <Box mb={12} display={["none", "block"]}>
              <Headline text="Highlights" />
              <List mt={4} fontSize={`lg`} spacing={4}>
                <ListItem fontSize={`md`}>
                  <ListIcon as={IconAmazon} />
                  <Link
                    href={`https://www.certmetrics.com/amazon/public/badge.aspx?i=1&t=c&d=2020-03-11&ci=AWS01187835`}
                    textDecor={`underline`}
                    isExternal
                  >
                    AWS Certified Solutions Architect - Associate
                  </Link>
                </ListItem>

                <ListItem fontSize={`md`}>
                  <ListIcon as={IconGraduation} />
                  <Link
                    href={`https://www.startupschool.org/`}
                    textDecor={`underline`}
                    isExternal
                  >
                    Startup School by Y Combinator, Batch of 2018
                  </Link>
                </ListItem>

                <ListItem>
                  <ListIcon as={IconWriting} />
                  <Link
                    href={`https://medium.com/@humzakhan`}
                    textDecor={`underline`}
                    isExternal
                  >
                    Occasional Writer on Medium
                  </Link>
                </ListItem>
              </List>
            </Box>
          </GridItem>
          <GridItem colSpan={[12, 9]}>
            <Box mb={12}>
              <Headline text="Introduction" />
              <Text mt={6} fontSize={`lg`}>
                As a Technical Product Engineer, something that I identify as, I
                focus on understanding the 'why' behind technical and business
                challenges, transforming them into well-architected solutions
                that are viable both technically and in business term.
              </Text>
              <Text mt={6} fontSize={`lg`}>
                I prioritize collaboration, effective communication, and
                adaptability in my work, valuing partnerships with organizations
                that embrace coherent design and development for mutual growth
              </Text>
            </Box>

            <Box mb={12}>
              <Headline text="Tech Stack" />
              {version === ResumeVersion.FS ? (
                <FullStackTechStack />
              ) : (
                <BackendTechStack />
              )}
            </Box>

            <Box mb={12}>
              <Headline text="Work History" />
              <Box my={6}>
                <List spacing={4}>
                  <ListItem>
                    <ListIcon as={IconRight} boxSize={5} />
                    <Text fontWeight={`bold`} display={`inline`}>
                      Senior Software Engineer - Lookout Inc - Aug 2022 to
                      present (1y 6m+)
                    </Text>
                    <List paddingLeft={8} spacing={2} mt={2}>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Core contributor in redesigning major ingestion service,
                        saving $60,000 USD annually in operational costs.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Led automation of certain compliance activities saving
                        6-8 development hours per week per person within the
                        team.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Boosted system observability by 35-45% by developing
                        DataDog-based monitoring infrastructure and integrating
                        cross-channel alerts.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Improved client-facing API's latency by ~55% by
                        introducing batched requests, database partitioning and
                        other methodologies.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Helped reduce infrastructure costs by 30% by optimizing
                        core services to smartly utilize compute resources.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Responsible for maintaining key logical units of certain
                        services that ingested company-wide data from ~12
                        million mobile devices.
                      </ListItem>
                    </List>
                  </ListItem>
                  <ListItem>
                    <ListIcon as={IconRight} boxSize={5} />
                    <Text fontWeight={`bold`} display={`inline`}>
                      Full Stack Developer - Revelate - Mar 2019 to July 2022
                      (3y 5m)
                    </Text>
                    <List paddingLeft={8} spacing={2} mt={2}>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Developed and scaled a real-time Transactional Cost
                        Analytics engine for financial markets, processing
                        200-400 million trade messages a day from venues such as
                        NYSE, NASDAQ, TSX.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Reduced metrics delivery timeframe from 30m to 12m by
                        optimizing data store and underlying orchestration jobs.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Saved significant % in execution costs by implementing a
                        circuit-breaker mechanism for query engine to catch any
                        timed-out analytic queries across clients.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Directly contributed to ARR $$$ by building a custom
                        DataTable component for CME Group with real-time
                        filtering and infinite scrolling capabilties.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Built and optimized orchestration jobs using Apache
                        Airflow, AWS Athena & Apache Kudu to deliver analytics
                        on a T-15 mins timeframe.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Built a React-based dashboard with necessory memory
                        optimizations to render on-demand tens of 1000s of
                        analytics rows, previously only available via REST API.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Attending Career Fairs to conduct workshops on the
                        technological feats achieved by the team and to
                        encourage big data among people entering the field.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Publishing technical content on behalf of the
                        organization to share the knowledge and information
                        accumulated internally during the development of the
                        systems.
                      </ListItem>
                    </List>
                  </ListItem>{" "}
                  <ListItem>
                    <ListIcon as={IconRight} boxSize={5} />
                    <Text fontWeight={`bold`} display={`inline`}>
                      Software Consultant - Self Employed - Feb 2018 to Jan 2019
                      (1y)
                    </Text>
                    <List paddingLeft={8} spacing={2} mt={2}>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Helped a startup in Ed-Tech close their seed funding
                        round by delivering technical guidance around their MVP.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Helped build new development teams from scratch with the
                        best practices and tech stacks relevant to the use-case.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Emphasis on brainstorming subtle details and features
                        required to ensure a seamless user experience by
                        prioritizing user engagement and interaction, followed
                        by the digital implementation of said features.
                      </ListItem>
                    </List>
                  </ListItem>
                  <ListItem>
                    <ListIcon as={IconRight} boxSize={5} />
                    <Text fontWeight={`bold`} display={`inline`}>
                      Full Stack Web Developer - Muballigh Travels - Nov 2016 to
                      Jan 2018 (1y 3m)
                    </Text>
                    <List paddingLeft={8} spacing={2} mt={2}>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Worked closely with the core development team to
                        identify areas which can be managed digitally and
                        implemented custom solutions for those specific bits.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Created RESTful API endpoints using Node.js and ASP.NET
                        Web API using the best architectural practices to
                        efficiently access the data store.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Developed a ReactJS web client to consume the API
                        endpoints
                      </ListItem>
                    </List>
                  </ListItem>
                  <ListItem>
                    <ListIcon as={IconRight} boxSize={5} />
                    <Text fontWeight={`bold`} display={`inline`}>
                      Inter Web Developer - Geneve Travel & Tours - May 2015 to
                      Aug 2016 (1y 4m)
                    </Text>
                    <List paddingLeft={8} spacing={2} mt={2}>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Worked closely with the core development team to assess
                        the requirements of the clients and designing their
                        digital implementations.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Designed responsive front-ends and worked on various
                        bits of back-end as per the requirements of the core
                        development team.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={IconSublist} />
                        Refactored the UX of the core content management system
                        utilized by the organization to enhance user engagement.
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </Box>
            </Box>

            <Box mb={12}>
              <Headline text="Certifications" />
              <Box my={6}>
                <List spacing={6}>
                  <ListItem>
                    <ListIcon as={IconRight} />
                    <Link
                      display={`inline`}
                      fontSize={`lg`}
                      fontWeight={`700`}
                      textDecor={`underline`}
                      href={`https://www.certmetrics.com/amazon/public/badge.aspx?i=1&t=c&d=2020-03-11&ci=AWS01187835`}
                      isExternal
                    >
                      AWS Certified Solutions Architect - Associate
                    </Link>
                    <Box my={3}>
                      <List paddingLeft={8} spacing={1}>
                        <ListItem>
                          <Text fontWeight={`bold`}>March 2020</Text>
                        </ListItem>
                        <ListItem>
                          <Text>
                            Certified with hands-on experience designing
                            available, cost-efficient, fault-tolerant, and
                            scalable distributed systems on AWS.
                          </Text>
                        </ListItem>
                      </List>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListIcon as={IconRight} />
                    <Link
                      display={`inline`}
                      fontSize={`lg`}
                      fontWeight={`700`}
                      textDecor={`underline`}
                      href={`https://www.startupschool.org/companies/edferal`}
                      isExternal
                    >
                      Startup School by Y Combinator - Graduate
                    </Link>
                    <Box my={3}>
                      <List paddingLeft={8} spacing={1}>
                        <ListItem>
                          <Text fontWeight={`bold`}>November 2018</Text>
                        </ListItem>
                        <ListItem>
                          <Text>
                            Successfully graduated from Startup School by the
                            world's largest and most valuable startup incubator
                            Y Combinator with extensive product training.
                          </Text>
                        </ListItem>
                      </List>
                    </Box>
                  </ListItem>
                </List>
              </Box>
            </Box>

            <Box mb={12}>
              <Headline text="References" />
              <Text mt={6}>References available on demand</Text>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </div>
  );
};
