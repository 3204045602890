import React from "react";
import { Box, Image, Tooltip } from "@chakra-ui/react";

interface MovieTileProps {
  url: string;
  title: string;
  cover: string;
  main?: boolean;
}

const MovieTile = (props: MovieTileProps) => {
  return (
    <a href={props.url} target="_blank" rel="noreferrer">
      <Tooltip
        hasArrow
        placement="top-start"
        label={props.title}
        padding={2}
        bgColor={`gray.800`}
        color={`white`}
        fontWeight={600}
        fontSize={`md`}
      >
        <Box
          width={props.main ? `180px` : `150px`}
          height={`auto`}
          transition="transform 0.3s"
          _hover={{ cursor: "pointer", transform: "scale(1.1)" }}
        >
          <Image
            src={props.cover}
            alt={props.title}
            position={`relative`}
            borderRadius={`2xl`}
          />
        </Box>
      </Tooltip>
    </a>
  );
};

export default MovieTile;
