import * as React from "react";
import { Box, Heading, Stack } from "@chakra-ui/react";
import { InterstellarAnimation } from "../components";

const Introduction = () => {
  return (
    <Box
      position="relative"
      zIndex="9"
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      fontSize="xl"
    >
      <InterstellarAnimation />
      <Stack direction={`column`} spacing={`4`}>
        <Heading as="h1" size={["2xl", "3xl", "4xl"]}>
          Sr Software Engineer —
        </Heading>
        <Heading as="h1" size={["xl", "2xl", "3xl"]}>
          Physics Enthusiast & DeFi Evangelist
        </Heading>
      </Stack>
    </Box>
  );
};

export default Introduction;
