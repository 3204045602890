import React from "react";

const InterstellarAnimation = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: "auto",
        background: "transparent",
        display: "block",
        zIndex: 1,
        position: "absolute",
      }}
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 1920 1080"
    >
      <g transform="translate(852.5,609) scale(1,1) translate(-852.5,-609)">
        <g transform="translate(31.24793611033283 445.62743158633924)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-790.0041277793343 282.2548631726785"
            keyTimes="0;1"
            dur="5s"
            begin="-3.9445150895823886s"
          ></animateTransform>

          <g transform="rotate(11.251031907220614)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.9445150895823886s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.9445150895823886s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.9445150895823886s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(836.80642473037 0.10319986042429719)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;821.1128494607401 -608.7936002791514"
            keyTimes="0;1"
            dur="5s"
            begin="-4.25228637626017s"
          ></animateTransform>

          <g transform="rotate(88.52359775305928)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.25228637626017s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.25228637626017s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.25228637626017s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1422.7199610323246 1061.7127247486694)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1992.9399220646494 1514.4254494973388"
            keyTimes="0;1"
            dur="5s"
            begin="-1.366239404993429s"
          ></animateTransform>

          <g transform="rotate(38.4469913786026)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.366239404993429s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.366239404993429s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.366239404993429s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1497.9290318596472 211.14196714475815)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2143.3580637192945 -186.7160657104837"
            keyTimes="0;1"
            dur="5s"
            begin="-4.026463705127115s"
          ></animateTransform>

          <g transform="rotate(148.34931755058187)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.026463705127115s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.026463705127115s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.026463705127115s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(649.7423333358286 1200.5245105548986)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;446.98466667165724 1792.0490211097972"
            keyTimes="0;1"
            dur="5s"
            begin="-0.33908418847961475s"
          ></animateTransform>

          <g transform="rotate(108.9202467894398)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.33908418847961475s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.33908418847961475s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.33908418847961475s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(234.35380161829823 189.61488837464532)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-383.79239676340353 -229.77022325070936"
            keyTimes="0;1"
            dur="5s"
            begin="-3.6529875438288206s"
          ></animateTransform>

          <g transform="rotate(34.15517314944347)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.6529875438288206s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.6529875438288206s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.6529875438288206s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(2.5831479490313995 561.6270423194528)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-847.3337041019372 514.2540846389055"
            keyTimes="0;1"
            dur="5s"
            begin="-2.5693682386438708s"
          ></animateTransform>

          <g transform="rotate(3.190270783025831)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.5693682386438708s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.5693682386438708s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.5693682386438708s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1288.2049352334757 85.54823534629816)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1723.9098704669514 -437.9035293074037"
            keyTimes="0;1"
            dur="5s"
            begin="-3.2604652577506963s"
          ></animateTransform>

          <g transform="rotate(129.77294214695857)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.2604652577506963s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.2604652577506963s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.2604652577506963s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(854.838891299081 1217.9977079746564)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;857.1777825981619 1826.995415949313"
            keyTimes="0;1"
            dur="5s"
            begin="-0.2416035584308096s"
          ></animateTransform>

          <g transform="rotate(89.77995329119048)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.2416035584308096s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.2416035584308096s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.2416035584308096s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(142.5068837315755 271.90688451165903)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-567.486232536849 -65.18623097668194"
            keyTimes="0;1"
            dur="5s"
            begin="-1.1054260779694736s"
          ></animateTransform>

          <g transform="rotate(25.39760277594273)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1054260779694736s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1054260779694736s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1054260779694736s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1661.0031471591928 415.8836970761032)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2469.5062943183857 222.76739415220635"
            keyTimes="0;1"
            dur="5s"
            begin="-4.50782136815249s"
          ></animateTransform>

          <g transform="rotate(166.56622745411337)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.50782136815249s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.50782136815249s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.50782136815249s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1019.169884799486 11.75231948480905)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1185.839769598972 -585.4953610303819"
            keyTimes="0;1"
            dur="5s"
            begin="-3.9395010870500933s"
          ></animateTransform>

          <g transform="rotate(105.59246503336782)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.9395010870500933s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.9395010870500933s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.9395010870500933s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(366.9441278424017 108.43555306544886)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-118.61174431519657 -392.1288938691023"
            keyTimes="0;1"
            dur="5s"
            begin="-3.2072260685936937s"
          ></animateTransform>

          <g transform="rotate(45.87196418636984)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.2072260685936937s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.2072260685936937s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.2072260685936937s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(952.9997104417891 4.246635482072179)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1053.4994208835783 -600.5067290358556"
            keyTimes="0;1"
            dur="5s"
            begin="-4.756674701599744s"
          ></animateTransform>

          <g transform="rotate(99.43535535750107)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.756674701599744s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.756674701599744s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.756674701599744s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1451.731959967516 1042.167952897817)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2050.963919935032 1475.335905795634"
            keyTimes="0;1"
            dur="5s"
            begin="-1.3008150174456923s"
          ></animateTransform>

          <g transform="rotate(35.862109387677386)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3008150174456923s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3008150174456923s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3008150174456923s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1660.4281682408389 414.65955519015256)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2468.3563364816778 220.31911038030512"
            keyTimes="0;1"
            dur="5s"
            begin="-1.1524726402082652s"
          ></animateTransform>

          <g transform="rotate(166.4749215840013)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1524726402082652s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1524726402082652s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1524726402082652s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(358.66498697276904 112.58606242483427)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-135.1700260544619 -383.82787515033147"
            keyTimes="0;1"
            dur="5s"
            begin="-3.2543144064806673s"
          ></animateTransform>

          <g transform="rotate(45.14921617248644)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.2543144064806673s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.2543144064806673s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.2543144064806673s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1638.1893412681193 372.66511610961993)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2423.8786825362386 136.33023221923986"
            keyTimes="0;1"
            dur="5s"
            begin="-1.325395174251932s"
          ></animateTransform>

          <g transform="rotate(163.25874451045502)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.325395174251932s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.325395174251932s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.325395174251932s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(0.2112352838892093 622.5563078789683)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-852.0775294322216 636.1126157579365"
            keyTimes="0;1"
            dur="5s"
            begin="-1.8419989126344094s"
          ></animateTransform>

          <g transform="rotate(179.0887434309751)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.8419989126344094s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.8419989126344094s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.8419989126344094s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(284.0598065629757 155.14649054768483)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-284.38038687404855 -298.70701890463033"
            keyTimes="0;1"
            dur="5s"
            begin="-0.5758344372061297s"
          ></animateTransform>

          <g transform="rotate(38.60453472162027)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5758344372061297s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5758344372061297s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5758344372061297s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1.0076325730844928 638.6010984021455)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-850.484734853831 668.202196804291"
            keyTimes="0;1"
            dur="5s"
            begin="-3.244797443793362s"
          ></animateTransform>

          <g transform="rotate(178.00898358824935)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.244797443793362s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.244797443793362s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.244797443793362s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1655.2113307353784 814.0760244146675)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2457.922661470757 1019.152048829335"
            keyTimes="0;1"
            dur="5s"
            begin="-3.371760042369755s"
          ></animateTransform>

          <g transform="rotate(14.331327072037784)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.371760042369755s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.371760042369755s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.371760042369755s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1545.0040701077373 964.1748341381792)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2237.5081402154747 1319.3496682763584"
            keyTimes="0;1"
            dur="5s"
            begin="-1.5345152211820834s"
          ></animateTransform>

          <g transform="rotate(27.152599516669344)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5345152211820834s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5345152211820834s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5345152211820834s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(173.82239816869924 977.5433315788971)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-504.8552036626015 1346.0866631577942"
            keyTimes="0;1"
            dur="5s"
            begin="-4.09560671276429s"
          ></animateTransform>

          <g transform="rotate(151.49664488479257)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.09560671276429s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.09560671276429s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.09560671276429s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1430.5068783714928 161.35512856070721)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2008.5137567429856 -286.28974287858557"
            keyTimes="0;1"
            dur="5s"
            begin="-4.049384818079936s"
          ></animateTransform>

          <g transform="rotate(142.24354739271334)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.049384818079936s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.049384818079936s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.049384818079936s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(597.1006895105879 1190.027699802426)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;341.70137902117597 1771.055399604852"
            keyTimes="0;1"
            dur="5s"
            begin="-0.7574310631694581s"
          ></animateTransform>

          <g transform="rotate(113.72859928447564)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7574310631694581s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7574310631694581s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7574310631694581s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1490.05175685311 1013.2877029756851)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2127.60351370622 1417.5754059513702"
            keyTimes="0;1"
            dur="5s"
            begin="-1.4064120347978815s"
          ></animateTransform>

          <g transform="rotate(32.37981812973965)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4064120347978815s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4064120347978815s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4064120347978815s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(798.4507925252385 1216.7747794536344)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;744.401585050477 1824.549558907269"
            keyTimes="0;1"
            dur="5s"
            begin="-0.8942575543684472s"
          ></animateTransform>

          <g transform="rotate(95.08192583043484)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8942575543684472s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8942575543684472s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8942575543684472s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1704.977054787398 604.5318451336173)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2557.454109574796 600.0636902672344"
            keyTimes="0;1"
            dur="5s"
            begin="-0.7023044692028613s"
          ></animateTransform>

          <g transform="rotate(179.69969388581754)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7023044692028613s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7023044692028613s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7023044692028613s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(760.3763464163073 1214.4337293749413)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;668.2526928326148 1819.8674587498829"
            keyTimes="0;1"
            dur="5s"
            begin="-4.686677829555062s"
          ></animateTransform>

          <g transform="rotate(98.65184197025252)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.686677829555062s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.686677829555062s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.686677829555062s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(885.016596593606 1217.5568336939061)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;917.5331931872119 1826.1136673878123"
            keyTimes="0;1"
            dur="5s"
            begin="-2.460936045882316s"
          ></animateTransform>

          <g transform="rotate(86.94146272947653)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.460936045882316s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.460936045882316s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.460936045882316s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1704.998835531988 610.0065810029639)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2557.497671063976 611.0131620059277"
            keyTimes="0;1"
            dur="5s"
            begin="-0.2586007370891408s"
          ></animateTransform>

          <g transform="rotate(0.06765148995449818)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.2586007370891408s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.2586007370891408s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.2586007370891408s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(318.8022063247122 134.1065651025229)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-214.89558735057562 -340.7868697949542"
            keyTimes="0;1"
            dur="5s"
            begin="-2.5980341771980155s"
          ></animateTransform>

          <g transform="rotate(41.66323524648151)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.5980341771980155s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.5980341771980155s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.5980341771980155s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(274.37505757737745 161.43294027508114)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-303.7498848452451 -286.1341194498377"
            keyTimes="0;1"
            dur="5s"
            begin="-4.0878358369128005s"
          ></animateTransform>

          <g transform="rotate(37.745966310638714)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.0878358369128005s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.0878358369128005s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.0878358369128005s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(127.55210762080185 288.5618353406864)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-597.3957847583963 -31.8763293186272"
            keyTimes="0;1"
            dur="5s"
            begin="-1.2922021795419059s"
          ></animateTransform>

          <g transform="rotate(23.846168927753553)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.2922021795419059s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.2922021795419059s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.2922021795419059s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(710.8137555491988 8.470022251735372)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;569.1275110983977 -592.0599554965293"
            keyTimes="0;1"
            dur="5s"
            begin="-2.764690210230034s"
          ></animateTransform>

          <g transform="rotate(76.72467304260721)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.764690210230034s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.764690210230034s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.764690210230034s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(424.10128511467235 1135.52026858538)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-4.297429770655299 1662.0405371707602"
            keyTimes="0;1"
            dur="5s"
            begin="-2.5156032873529863s"
          ></animateTransform>

          <g transform="rotate(129.13323093525602)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.5156032873529863s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.5156032873529863s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.5156032873529863s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1704.0579114173402 637.6227231680003)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2555.6158228346803 666.2454463360007"
            keyTimes="0;1"
            dur="5s"
            begin="-3.260993538994522s"
          ></animateTransform>

          <g transform="rotate(1.9251116501544263)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.260993538994522s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.260993538994522s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.260993538994522s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(836.6550080683576 1217.894798948933)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;820.8100161367151 1826.7895978978659"
            keyTimes="0;1"
            dur="5s"
            begin="-1.5855934828087759s"
          ></animateTransform>

          <g transform="rotate(91.49064555132787)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5855934828087759s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5855934828087759s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5855934828087759s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1533.0870171085999 975.7419972944358)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2213.6740342171997 1342.4839945888716"
            keyTimes="0;1"
            dur="5s"
            begin="-0.9260171197256695s"
          ></animateTransform>

          <g transform="rotate(28.318508669363215)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.9260171197256695s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.9260171197256695s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.9260171197256695s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(156.28908438163955 960.457170317906)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-539.9218312367209 1311.914340635812"
            keyTimes="0;1"
            dur="5s"
            begin="-2.0404897894641048s"
          ></animateTransform>

          <g transform="rotate(153.21470643814206)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.0404897894641048s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.0404897894641048s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.0404897894641048s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(70.01549597249493 850.700088933416)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-712.4690080550101 1092.400177866832"
            keyTimes="0;1"
            dur="5s"
            begin="-2.7237251420314665s"
          ></animateTransform>

          <g transform="rotate(162.834707953108)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.7237251420314665s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.7237251420314665s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.7237251420314665s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1331.1570502392483 1112.9438513192997)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1809.8141004784968 1616.8877026385994"
            keyTimes="0;1"
            dur="5s"
            begin="-1.8257163241427854s"
          ></animateTransform>

          <g transform="rotate(46.47415625914998)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.8257163241427854s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.8257163241427854s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.8257163241427854s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1703.8083200440115 576.8105845875896)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2555.116640088023 544.6211691751791"
            keyTimes="0;1"
            dur="5s"
            begin="-0.7817620933729763s"
          ></animateTransform>

          <g transform="rotate(177.83458072422778)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7817620933729763s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7817620933729763s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7817620933729763s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(803.1442502181902 1216.9784999037806)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;753.7885004363804 1824.9569998075615"
            keyTimes="0;1"
            dur="5s"
            begin="-4.81933827502179s"
          ></animateTransform>

          <g transform="rotate(94.64109916629266)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.81933827502179s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.81933827502179s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.81933827502179s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1527.9550329285441 237.44767408253432)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2203.4100658570883 -134.10465183493136"
            keyTimes="0;1"
            dur="5s"
            begin="-1.4043623296284191s"
          ></animateTransform>

          <g transform="rotate(151.1858160970585)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4043623296284191s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4043623296284191s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4043623296284191s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(168.1764129908645 972.1762636404362)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-516.147174018271 1335.3525272808724"
            keyTimes="0;1"
            dur="5s"
            begin="-4.460233551341144s"
          ></animateTransform>

          <g transform="rotate(152.04473302437074)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.460233551341144s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.460233551341144s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.460233551341144s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(4.032355029310224 549.8369829923204)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-844.4352899413796 490.67396598464086"
            keyTimes="0;1"
            dur="5s"
            begin="-0.1214258619150177s"
          ></animateTransform>

          <g transform="rotate(3.988735760560566)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.1214258619150177s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.1214258619150177s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.1214258619150177s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(206.3354433152549 211.7517059235047)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-439.8291133694902 -185.49658815299063"
            keyTimes="0;1"
            dur="5s"
            begin="-1.1913706933736223s"
          ></animateTransform>

          <g transform="rotate(31.582320533614975)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1913706933736223s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1913706933736223s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1913706933736223s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(24.597974337723826 463.76236272157894)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-803.3040513245523 318.5247254431578"
            keyTimes="0;1"
            dur="5s"
            begin="-4.0249091935849055s"
          ></animateTransform>

          <g transform="rotate(9.950067271356401)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.0249091935849055s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.0249091935849055s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.0249091935849055s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(754.7300271693891 1213.981678634767)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;656.9600543387784 1818.963357269534"
            keyTimes="0;1"
            dur="5s"
            begin="-0.5264770028446075s"
          ></animateTransform>

          <g transform="rotate(99.18009488331218)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5264770028446075s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5264770028446075s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5264770028446075s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(9.980403313916781 701.9146751042498)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-832.5391933721664 794.8293502084996"
            keyTimes="0;1"
            dur="5s"
            begin="-4.78100606100284s"
          ></animateTransform>

          <g transform="rotate(173.70674253182182)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.78100606100284s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.78100606100284s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.78100606100284s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1697.2706525366557 690.8219586451017)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2542.0413050733114 772.6439172902035"
            keyTimes="0;1"
            dur="5s"
            begin="-4.109786978137421s"
          ></animateTransform>

          <g transform="rotate(5.5322410612284445)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.109786978137421s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.109786978137421s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.109786978137421s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1600.1744866356246 316.42307253828716)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2347.848973271249 23.846145076574317"
            keyTimes="0;1"
            dur="5s"
            begin="-0.8778280343246203s"
          ></animateTransform>

          <g transform="rotate(158.62880288076295)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8778280343246203s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8778280343246203s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8778280343246203s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1678.9332134905912 459.55432092564786)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2505.3664269811825 310.1086418512957"
            keyTimes="0;1"
            dur="5s"
            begin="-1.2809922371499094s"
          ></animateTransform>

          <g transform="rotate(169.74985167124245)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.2809922371499094s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.2809922371499094s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.2809922371499094s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(13.944939825201573 499.2991225310471)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-824.6101203495969 389.5982450620942"
            keyTimes="0;1"
            dur="5s"
            begin="-2.0395276278751995s"
          ></animateTransform>

          <g transform="rotate(7.453183202625523)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.0395276278751995s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.0395276278751995s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.0395276278751995s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1438.9749747610133 167.01170109401198)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2025.4499495220266 -274.97659781197603"
            keyTimes="0;1"
            dur="5s"
            begin="-4.28196166543425s"
          ></animateTransform>

          <g transform="rotate(142.99702744456147)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.28196166543425s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.28196166543425s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.28196166543425s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1661.6428220919702 417.2557955267571)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2470.7856441839403 225.5115910535141"
            keyTimes="0;1"
            dur="5s"
            begin="-3.96469315765837s"
          ></animateTransform>

          <g transform="rotate(166.6684210453694)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.96469315765837s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.96469315765837s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.96469315765837s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(975.5506528399094 1211.6225611551708)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1098.6013056798188 1814.2451223103415"
            keyTimes="0;1"
            dur="5s"
            begin="-4.398488647947953s"
          ></animateTransform>

          <g transform="rotate(78.45931358935526)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.398488647947953s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.398488647947953s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.398488647947953s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(444.3794653747218 74.32228345856038)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;36.25893074944361 -460.35543308287924"
            keyTimes="0;1"
            dur="5s"
            begin="-1.500292289722055s"
          ></animateTransform>

          <g transform="rotate(52.645435987326344)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.500292289722055s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.500292289722055s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.500292289722055s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(47.59579344817905 408.3582029533206)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-757.3084131036419 207.71640590664117"
            keyTimes="0;1"
            dur="5s"
            begin="-4.020801788128418s"
          ></animateTransform>

          <g transform="rotate(13.997094010860287)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.020801788128418s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.020801788128418s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.020801788128418s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1276.6561686475584 80.72976976428424)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1700.812337295117 -447.5404604714315"
            keyTimes="0;1"
            dur="5s"
            begin="-4.363749966365967s"
          ></animateTransform>

          <g transform="rotate(128.76150835673542)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.363749966365967s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.363749966365967s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.363749966365967s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(133.39454349242328 936.0873148055693)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-585.7109130151534 1263.1746296111387"
            keyTimes="0;1"
            dur="5s"
            begin="-1.6096497611525395s"
          ></animateTransform>

          <g transform="rotate(155.5414404791769)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.6096497611525395s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.6096497611525395s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.6096497611525395s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1641.8064106419818 378.89800164527526)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2431.1128212839635 148.79600329055046"
            keyTimes="0;1"
            dur="5s"
            begin="-0.8744779649984802s"
          ></animateTransform>

          <g transform="rotate(163.74731311195984)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8744779649984802s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8744779649984802s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8744779649984802s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1004.9849593765498 9.821292582363867)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1157.4699187530996 -589.3574148352723"
            keyTimes="0;1"
            dur="5s"
            begin="-3.3127260143213344s"
          ></animateTransform>

          <g transform="rotate(104.27810904360304)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.3127260143213344s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.3127260143213344s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.3127260143213344s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1450.0714829063704 1043.3369934070338)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2047.6429658127406 1477.6739868140676"
            keyTimes="0;1"
            dur="5s"
            begin="-2.0730236899280197s"
          ></animateTransform>

          <g transform="rotate(36.011036898859935)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.0730236899280197s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.0730236899280197s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.0730236899280197s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1126.2903297664307 1185.7378476832673)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1400.0806595328613 1762.4756953665346"
            keyTimes="0;1"
            dur="5s"
            begin="-3.3292807075285147s"
          ></animateTransform>

          <g transform="rotate(64.60526427663541)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.3292807075285147s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.3292807075285147s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.3292807075285147s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1703.4841780847112 572.6992123916664)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2554.4683561694223 536.3984247833326"
            keyTimes="0;1"
            dur="5s"
            begin="-4.13323249234146s"
          ></animateTransform>

          <g transform="rotate(177.55739084696225)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.13323249234146s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.13323249234146s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.13323249234146s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(561.4324551200126 36.59611473515861)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;270.3649102400252 -535.8077705296828"
            keyTimes="0;1"
            dur="5s"
            begin="-4.411688415764832s"
          ></animateTransform>

          <g transform="rotate(63.04665019758876)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.411688415764832s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.411688415764832s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.411688415764832s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(167.70885238307562 971.7262296872158)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-517.0822952338488 1334.4524593744316"
            keyTimes="0;1"
            dur="5s"
            begin="-4.275285833160778s"
          ></animateTransform>

          <g transform="rotate(152.09033046679087)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.275285833160778s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.275285833160778s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.275285833160778s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1360.9606257984813 1097.8208927330907)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1869.4212515969625 1586.641785466181"
            keyTimes="0;1"
            dur="5s"
            begin="-4.453566241637999s"
          ></animateTransform>

          <g transform="rotate(43.87180464228794)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.453566241637999s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.453566241637999s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.453566241637999s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1098.967635575449 26.007100697975943)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1345.4352711508982 -556.9857986040481"
            keyTimes="0;1"
            dur="5s"
            begin="-2.744069318770266s"
          ></animateTransform>

          <g transform="rotate(112.91682697740289)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.744069318770266s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.744069318770266s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.744069318770266s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1524.0474042834317 233.85034496869844)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2195.5948085668633 -141.29931006260313"
            keyTimes="0;1"
            dur="5s"
            begin="-0.9797361657863157s"
          ></animateTransform>

          <g transform="rotate(150.81076092173478)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.9797361657863157s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.9797361657863157s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.9797361657863157s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(0.01677262558393977 612.8201770098311)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-852.4664547488321 616.6403540196623"
            keyTimes="0;1"
            dur="5s"
            begin="-4.615278559277131s"
          ></animateTransform>

          <g transform="rotate(179.74324591088538)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.615278559277131s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.615278559277131s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.615278559277131s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1356.3807921625812 117.76491621200591)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1860.2615843251624 -373.47016757598817"
            keyTimes="0;1"
            dur="5s"
            begin="-4.529941733044125s"
          ></animateTransform>

          <g transform="rotate(135.72806266928663)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.529941733044125s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.529941733044125s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.529941733044125s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1529.7587599369174 978.8729219861866)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2207.0175198738348 1348.7458439723732"
            keyTimes="0;1"
            dur="5s"
            begin="-1.5322171927740857s"
          ></animateTransform>

          <g transform="rotate(28.6403818102798)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5322171927740857s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5322171927740857s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5322171927740857s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1454.0005037493352 1040.560421299753)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2055.5010074986703 1472.120842599506"
            keyTimes="0;1"
            dur="5s"
            begin="-4.295109126887939s"
          ></animateTransform>

          <g transform="rotate(35.65842150693604)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.295109126887939s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.295109126887939s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.295109126887939s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1362.764362472746 1096.8607785905454)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1873.0287249454918 1584.7215571810907"
            keyTimes="0;1"
            dur="5s"
            begin="-4.474206837407396s"
          ></animateTransform>

          <g transform="rotate(43.71417395779256)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.474206837407396s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.474206837407396s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.474206837407396s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(156.83022592616828 961.0035805523396)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-538.8395481476634 1313.0071611046792"
            keyTimes="0;1"
            dur="5s"
            begin="-0.7045882990928076s"
          ></animateTransform>

          <g transform="rotate(153.16093882492362)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7045882990928076s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7045882990928076s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7045882990928076s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1525.7494211019934 235.41038492096664)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2198.998842203987 -138.17923015806673"
            keyTimes="0;1"
            dur="5s"
            begin="-2.013274624968191s"
          ></animateTransform>

          <g transform="rotate(150.97387735754685)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.013274624968191s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.013274624968191s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.013274624968191s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1704.9991658120766 608.14804444405)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2557.4983316241533 607.2960888881"
            keyTimes="0;1"
            dur="5s"
            begin="-1.282662078253478s"
          ></animateTransform>

          <g transform="rotate(179.94274077513103)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.282662078253478s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.282662078253478s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.282662078253478s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(240.3121143167234 185.17820941338795)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-371.8757713665532 -238.6435811732241"
            keyTimes="0;1"
            dur="5s"
            begin="-3.0809706846265286s"
          ></animateTransform>

          <g transform="rotate(34.6951160885711)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.0809706846265286s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.0809706846265286s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.0809706846265286s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(50.450107812313945 815.3924888334992)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-751.5997843753721 1021.7849776669984"
            keyTimes="0;1"
            dur="5s"
            begin="-1.829430654105756s"
          ></animateTransform>

          <g transform="rotate(165.56910352014378)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.829430654105756s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.829430654105756s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.829430654105756s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1029.6732972183106 13.297268864416878)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1206.8465944366212 -582.4054622711662"
            keyTimes="0;1"
            dur="5s"
            begin="-0.344925114416339s"
          ></animateTransform>

          <g transform="rotate(106.56347676419982)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.344925114416339s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.344925114416339s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.344925114416339s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(595.1818673326442 28.404508752008383)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;337.86373466528835 -552.1909824959832"
            keyTimes="0;1"
            dur="5s"
            begin="-1.7438714387281895s"
          ></animateTransform>

          <g transform="rotate(66.0972264383721)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.7438714387281895s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.7438714387281895s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.7438714387281895s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(392.66113174719857 1121.8080780736213)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-67.17773650560287 1634.6161561472427"
            keyTimes="0;1"
            dur="5s"
            begin="-4.294262538760901s"
          ></animateTransform>

          <g transform="rotate(131.88281834580582)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.294262538760901s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.294262538760901s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.294262538760901s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(235.6034695266975 188.67690743365756)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-381.293060946605 -231.64618513268488"
            keyTimes="0;1"
            dur="5s"
            begin="-3.25496034818444s"
          ></animateTransform>

          <g transform="rotate(34.26860165698397)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.25496034818444s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.25496034818444s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.25496034818444s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1004.5385101368034 9.763398880699924)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1156.5770202736069 -589.4732022386002"
            keyTimes="0;1"
            dur="5s"
            begin="-4.685995323301535s"
          ></animateTransform>

          <g transform="rotate(104.2366877922294)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.685995323301535s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.685995323301535s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.685995323301535s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(165.4992526583345 248.41232734703823)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-521.501494683331 -112.17534530592354"
            keyTimes="0;1"
            dur="5s"
            begin="-1.468906258124949s"
          ></animateTransform>

          <g transform="rotate(27.6937374258162)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.468906258124949s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.468906258124949s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.468906258124949s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(715.0400001999311 1210.0310339076455)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;577.5800003998622 1811.0620678152907"
            keyTimes="0;1"
            dur="5s"
            begin="-0.6996630547333549s"
          ></animateTransform>

          <g transform="rotate(102.88238289974088)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.6996630547333549s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.6996630547333549s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.6996630547333549s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(982.3660202393598 7.10773731967447)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1112.2320404787197 -594.7845253606511"
            keyTimes="0;1"
            dur="5s"
            begin="-1.3040316184132394s"
          ></animateTransform>

          <g transform="rotate(102.17565331264964)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3040316184132394s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3040316184132394s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3040316184132394s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1488.0449949587096 203.10306353929667)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2123.589989917419 -202.79387292140666"
            keyTimes="0;1"
            dur="5s"
            begin="-1.3517433057123285s"
          ></animateTransform>

          <g transform="rotate(147.43527155886022)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3517433057123285s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3517433057123285s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3517433057123285s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(763.349981634256 1214.6608675682594)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;674.1999632685121 1820.3217351365188"
            keyTimes="0;1"
            dur="5s"
            begin="-0.9476551893420448s"
          ></animateTransform>

          <g transform="rotate(98.37350156974665)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.9476551893420448s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.9476551893420448s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.9476551893420448s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(88.63412125040907 879.3916111735264)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-675.2317574991819 1149.7832223470527"
            keyTimes="0;1"
            dur="5s"
            begin="-3.4741856989480437s"
          ></animateTransform>

          <g transform="rotate(160.5071640614926)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.4741856989480437s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.4741856989480437s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.4741856989480437s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1499.4069413570044 212.3687877476113)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2146.3138827140087 -184.2624245047774"
            keyTimes="0;1"
            dur="5s"
            begin="-1.9005791687379936s"
          ></animateTransform>

          <g transform="rotate(148.48672943906286)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.9005791687379936s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.9005791687379936s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.9005791687379936s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(319.1514794537271 133.90635934718307)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-214.19704109254576 -341.18728130563386"
            keyTimes="0;1"
            dur="5s"
            begin="-3.771174620765585s"
          ></animateTransform>

          <g transform="rotate(41.693857572530426)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.771174620765585s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.771174620765585s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.771174620765585s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(332.38394240220043 126.47762152106412)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-187.73211519559914 -356.04475695787175"
            keyTimes="0;1"
            dur="5s"
            begin="-1.786804836527136s"
          ></animateTransform>

          <g transform="rotate(42.852714859875)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.786804836527136s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.786804836527136s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.786804836527136s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(104.72205788754161 901.4419684139716)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-643.0558842249168 1193.8839368279432"
            keyTimes="0;1"
            dur="5s"
            begin="-3.3005288844762806s"
          ></animateTransform>

          <g transform="rotate(158.64046137837198)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.3005288844762806s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.3005288844762806s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.3005288844762806s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1489.11076873207 203.95628556361453)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2125.72153746414 -201.08742887277094"
            keyTimes="0;1"
            dur="5s"
            begin="-0.10341076519059023s"
          ></animateTransform>

          <g transform="rotate(147.53343493649862)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.10341076519059023s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.10341076519059023s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.10341076519059023s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1263.3358222504585 75.3845462187943)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1674.171644500917 -458.2309075624114"
            keyTimes="0;1"
            dur="5s"
            begin="-3.8846081238042327s"
          ></animateTransform>

          <g transform="rotate(127.59303026347793)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.8846081238042327s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.8846081238042327s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.8846081238042327s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1623.0975735436998 348.53846035588106)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2393.6951470873996 88.07692071176211"
            keyTimes="0;1"
            dur="5s"
            begin="-1.1206597283305353s"
          ></animateTransform>

          <g transform="rotate(161.32477611536945)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1206597283305353s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1206597283305353s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1206597283305353s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(466.6642976761877 1152.05571717794)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;80.82859535237537 1695.1114343558802"
            keyTimes="0;1"
            dur="5s"
            begin="-2.767434300189404s"
          ></animateTransform>

          <g transform="rotate(125.39341634185305)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.767434300189404s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.767434300189404s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.767434300189404s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(494.1171299944764 56.42793222301759)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;135.73425998895277 -496.1441355539648"
            keyTimes="0;1"
            dur="5s"
            begin="-0.07734901087773194s"
          ></animateTransform>

          <g transform="rotate(57.0336800741471)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.07734901087773194s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.07734901087773194s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.07734901087773194s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(750.3424349105926 1213.6115903583668)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;648.1848698211853 1818.2231807167339"
            keyTimes="0;1"
            dur="5s"
            begin="-4.219585522384694s"
          ></animateTransform>

          <g transform="rotate(99.5903417567002)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.219585522384694s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.219585522384694s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.219585522384694s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1069.1445253041593 1198.0068162097095)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1285.7890506083188 1787.0136324194193"
            keyTimes="0;1"
            dur="5s"
            begin="-4.743819399830592s"
          ></animateTransform>

          <g transform="rotate(69.80580798645565)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.743819399830592s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.743819399830592s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.743819399830592s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(97.44507641899872 326.2609690422773)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-657.6098471620026 43.52193808455456"
            keyTimes="0;1"
            dur="5s"
            begin="-0.11234329755459016s"
          ></animateTransform>

          <g transform="rotate(20.52899265396713)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.11234329755459016s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.11234329755459016s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.11234329755459016s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1666.5898664127708 428.25754282561354)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2480.6797328255416 247.51508565122703"
            keyTimes="0;1"
            dur="5s"
            begin="-1.3698741195165731s"
          ></animateTransform>

          <g transform="rotate(167.482353051288)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3698741195165731s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3698741195165731s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3698741195165731s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1643.4693035490527 836.1692890009226)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2434.4386070981054 1063.3385780018452"
            keyTimes="0;1"
            dur="5s"
            begin="-1.6623178403841499s"
          ></animateTransform>

          <g transform="rotate(16.024259992653537)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.6623178403841499s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.6623178403841499s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.6623178403841499s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(250.31268056285262 177.92865254230793)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-351.87463887429476 -253.14269491538414"
            keyTimes="0;1"
            dur="5s"
            begin="-1.6876456909127024s"
          ></animateTransform>

          <g transform="rotate(35.59670184043128)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.6876456909127024s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.6876456909127024s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.6876456909127024s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(0.73215848238533 583.7655189009297)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-851.0356830352293 558.5310378018595"
            keyTimes="0;1"
            dur="5s"
            begin="-0.0768355971195489s"
          ></animateTransform>

          <g transform="rotate(1.69694888175309)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.0768355971195489s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.0768355971195489s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.0768355971195489s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1675.6218289187354 767.4978178917977)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2498.743657837471 925.9956357835954"
            keyTimes="0;1"
            dur="5s"
            begin="-0.742427946039268s"
          ></animateTransform>

          <g transform="rotate(10.899297772862138)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.742427946039268s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.742427946039268s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.742427946039268s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1694.7266571733892 514.7397416324138)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2536.9533143467784 420.47948326482765"
            keyTimes="0;1"
            dur="5s"
            begin="-0.7203235338602265s"
          ></animateTransform>

          <g transform="rotate(173.61414912023278)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7203235338602265s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7203235338602265s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7203235338602265s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1661.090780077469 801.9289717348759)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2469.681560154938 994.8579434697516"
            keyTimes="0;1"
            dur="5s"
            begin="-1.2993856253794644s"
          ></animateTransform>

          <g transform="rotate(13.419810969439906)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.2993856253794644s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.2993856253794644s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.2993856253794644s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(32.14002452914451 443.35596462340976)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-788.219950941711 277.7119292468195"
            keyTimes="0;1"
            dur="5s"
            begin="-4.047900921975471s"
          ></animateTransform>

          <g transform="rotate(11.415465403557105)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.047900921975471s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.047900921975471s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.047900921975471s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(793.4209981090953 1216.5358445132676)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;734.3419962181905 1824.0716890265353"
            keyTimes="0;1"
            dur="5s"
            begin="-2.522238412163369s"
          ></animateTransform>

          <g transform="rotate(95.5541871497266)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.522238412163369s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.522238412163369s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.522238412163369s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1479.9862177460673 196.75414239159232)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2107.4724354921345 -215.49171521681535"
            keyTimes="0;1"
            dur="5s"
            begin="-4.902828474892487s"
          ></animateTransform>

          <g transform="rotate(146.69588889202547)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.902828474892487s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.902828474892487s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.902828474892487s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(178.2040196090012 981.625143979453)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-496.0919607819976 1354.250287958906"
            keyTimes="0;1"
            dur="5s"
            begin="-4.938961522756811s"
          ></animateTransform>

          <g transform="rotate(151.07436232744456)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.938961522756811s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.938961522756811s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.938961522756811s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(47.71317522551908 809.881944389636)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-757.0736495489618 1010.7638887792721"
            keyTimes="0;1"
            dur="5s"
            begin="-1.4098202865433695s"
          ></animateTransform>

          <g transform="rotate(165.98484936002498)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4098202865433695s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4098202865433695s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4098202865433695s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1339.6641910574133 109.23366207757562)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1826.8283821148266 -390.53267584484877"
            keyTimes="0;1"
            dur="5s"
            begin="-1.4680856024118227s"
          ></animateTransform>

          <g transform="rotate(134.26842815391635)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4680856024118227s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4680856024118227s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4680856024118227s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1468.8558003065004 1029.7277207400818)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2085.211600613001 1450.4554414801637"
            keyTimes="0;1"
            dur="5s"
            begin="-3.37491281773414s"
          ></animateTransform>

          <g transform="rotate(34.31765133150538)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.37491281773414s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.37491281773414s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.37491281773414s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(653.175119145852 16.880394042165676)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;453.85023829170416 -575.2392119156686"
            keyTimes="0;1"
            dur="5s"
            begin="-3.4343750916043048s"
          ></animateTransform>

          <g transform="rotate(71.39525279059504)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.4343750916043048s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.4343750916043048s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.4343750916043048s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(136.52013948176386 939.5679465199173)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-579.4597210364723 1270.1358930398346"
            keyTimes="0;1"
            dur="5s"
            begin="-4.941734139495769s"
          ></animateTransform>

          <g transform="rotate(155.21722681630146)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.941734139495769s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.941734139495769s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.941734139495769s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1191.2895611346516 50.15577349738521)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1530.0791222693035 -508.6884530052296"
            keyTimes="0;1"
            dur="5s"
            begin="-1.9618929710687316s"
          ></animateTransform>

          <g transform="rotate(121.2256074082535)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.9618929710687316s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.9618929710687316s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.9618929710687316s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(754.3451430402308 4.050127030882322)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;656.1902860804618 -600.8997459382354"
            keyTimes="0;1"
            dur="5s"
            begin="-3.3916068270858735s"
          ></animateTransform>

          <g transform="rotate(80.7839091649662)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.3916068270858735s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.3916068270858735s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.3916068270858735s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(704.8176768453793 1208.7923001033157)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;557.1353536907586 1808.5846002066314"
            keyTimes="0;1"
            dur="5s"
            begin="-4.591637354113844s"
          ></animateTransform>

          <g transform="rotate(103.83235647964456)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.591637354113844s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.591637354113844s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.591637354113844s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(113.27022514342036 912.33006415853)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-625.9595497131593 1215.66012831706"
            keyTimes="0;1"
            dur="5s"
            begin="-4.027109399143684s"
          ></animateTransform>

          <g transform="rotate(157.69005933327603)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.027109399143684s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.027109399143684s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.027109399143684s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1095.8044113659323 25.329421463841868)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1339.1088227318646 -558.3411570723163"
            keyTimes="0;1"
            dur="5s"
            begin="-4.638634532874152s"
          ></animateTransform>

          <g transform="rotate(112.62892600174291)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.638634532874152s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.638634532874152s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.638634532874152s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(841.0432331034833 0.05499741610549336)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;829.5864662069665 -608.890005167789"
            keyTimes="0;1"
            dur="5s"
            begin="-4.905414353903658s"
          ></animateTransform>

          <g transform="rotate(88.92215725245387)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.905414353903658s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.905414353903658s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.905414353903658s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(467.1872428492068 65.75483490631007)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;81.87448569841365 -477.49033018737987"
            keyTimes="0;1"
            dur="5s"
            begin="-1.5252652884382178s"
          ></animateTransform>

          <g transform="rotate(54.652696663463935)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5252652884382178s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5252652884382178s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5252652884382178s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(34.157487223026806 779.660738525959)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-784.1850255539464 950.3214770519179"
            keyTimes="0;1"
            dur="5s"
            begin="-4.351146534524721s"
          ></animateTransform>

          <g transform="rotate(168.22012203075624)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.351146534524721s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.351146534524721s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.351146534524721s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1116.35594304756 29.90391194534925)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1380.21188609512 -549.1921761093015"
            keyTimes="0;1"
            dur="5s"
            begin="-0.9345031285871508s"
          ></animateTransform>

          <g transform="rotate(114.49562923752886)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.9345031285871508s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.9345031285871508s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.9345031285871508s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1573.3591384905712 934.1113985851287)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2294.2182769811425 1259.2227971702573"
            keyTimes="0;1"
            dur="5s"
            begin="-4.929028713168724s"
          ></animateTransform>

          <g transform="rotate(24.275634080568878)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.929028713168724s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.929028713168724s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.929028713168724s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(147.14356877061584 266.9754633178457)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-558.2128624587683 -75.04907336430858"
            keyTimes="0;1"
            dur="5s"
            begin="-3.963177400925262s"
          ></animateTransform>

          <g transform="rotate(25.868562618588378)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.963177400925262s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.963177400925262s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.963177400925262s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1623.3648153771237 869.0576651496061)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2394.2296307542474 1129.1153302992122"
            keyTimes="0;1"
            dur="5s"
            begin="-3.904161120625634s"
          ></animateTransform>

          <g transform="rotate(18.642251280777174)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.904161120625634s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.904161120625634s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.904161120625634s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1134.9161449606283 1183.6113008327666)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1417.3322899212567 1758.2226016655334"
            keyTimes="0;1"
            dur="5s"
            begin="-2.830308391660826s"
          ></animateTransform>

          <g transform="rotate(63.82630966068335)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.830308391660826s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.830308391660826s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.830308391660826s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(36.821745534471006 786.0501581323989)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-778.856508931058 963.1003162647978"
            keyTimes="0;1"
            dur="5s"
            begin="-4.3083468977933155s"
          ></animateTransform>

          <g transform="rotate(167.7534179081078)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.3083468977933155s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.3083468977933155s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.3083468977933155s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(46.589642017138885 807.5699801918536)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-759.3207159657222 1006.139960383707"
            keyTimes="0;1"
            dur="5s"
            begin="-1.2357847617353401s"
          ></animateTransform>

          <g transform="rotate(166.15847635518898)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.2357847617353401s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.2357847617353401s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.2357847617353401s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1651.3006723801107 821.7259186204232)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2450.1013447602213 1034.4518372408463"
            keyTimes="0;1"
            dur="5s"
            begin="-2.8936803139590097s"
          ></animateTransform>

          <g transform="rotate(14.912156638888641)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.8936803139590097s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.8936803139590097s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.8936803139590097s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(464.155414492455 1151.1423206428567)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;75.81082898491002 1693.2846412857136"
            keyTimes="0;1"
            dur="5s"
            begin="-2.1790040699882773s"
          ></animateTransform>

          <g transform="rotate(125.61458684135201)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.1790040699882773s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.1790040699882773s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.1790040699882773s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(590.415929533581 29.49344329317944)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;328.331859067162 -550.0131134136411"
            keyTimes="0;1"
            dur="5s"
            begin="-1.3064783281618753s"
          ></animateTransform>

          <g transform="rotate(65.66495569973729)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3064783281618753s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3064783281618753s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.3064783281618753s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(180.9227805268231 984.1224166736413)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-490.6544389463538 1359.2448333472826"
            keyTimes="0;1"
            dur="5s"
            begin="-1.8293058166892873s"
          ></animateTransform>

          <g transform="rotate(150.81361514256443)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.8293058166892873s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.8293058166892873s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.8293058166892873s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(17.043053013141048 730.1650274608779)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-818.4138939737179 851.3300549217558"
            keyTimes="0;1"
            dur="5s"
            begin="-0.13399982633912288s"
          ></animateTransform>

          <g transform="rotate(171.748016185124)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.13399982633912288s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.13399982633912288s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.13399982633912288s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(28.049409493514645 454.0666541518036)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-796.4011810129707 299.1333083036072"
            keyTimes="0;1"
            dur="5s"
            begin="-2.628156894891119s"
          ></animateTransform>

          <g transform="rotate(10.64307437163351)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.628156894891119s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.628156894891119s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.628156894891119s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(972.3509947125584 1211.9515536069434)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1092.2019894251168 1814.903107213887"
            keyTimes="0;1"
            dur="5s"
            begin="-2.932696858053271s"
          ></animateTransform>

          <g transform="rotate(78.7576352363418)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.932696858053271s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.932696858053271s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.932696858053271s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1633.5262880489615 853.0951428701362)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2414.552576097923 1097.1902857402724"
            keyTimes="0;1"
            dur="5s"
            begin="-1.0491651781163858s"
          ></animateTransform>

          <g transform="rotate(17.355657207305164)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.0491651781163858s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.0491651781163858s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.0491651781163858s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1704.9601253943347 614.8901752806474)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2557.4202507886694 620.7803505612949"
            keyTimes="0;1"
            dur="5s"
            begin="-0.5912982615654816s"
          ></animateTransform>

          <g transform="rotate(0.3958857470137373)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5912982615654816s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5912982615654816s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5912982615654816s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(444.2278238969922 1143.618633226765)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;35.95564779398444 1678.23726645353"
            keyTimes="0;1"
            dur="5s"
            begin="-2.7047278549986653s"
          ></animateTransform>

          <g transform="rotate(127.36788432855597)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.7047278549986653s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.7047278549986653s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.7047278549986653s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(696.673633785367 10.260164060950842)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;540.8472675707341 -588.4796718780983"
            keyTimes="0;1"
            dur="5s"
            begin="-2.1138546942395786s"
          ></animateTransform>

          <g transform="rotate(75.41198013616503)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.1138546942395786s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.1138546942395786s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.1138546942395786s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(785.6029680751928 1216.1220572717625)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;718.7059361503857 1823.2441145435248"
            keyTimes="0;1"
            dur="5s"
            begin="-2.3725985209855582s"
          ></animateTransform>

          <g transform="rotate(96.28789137304568)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.3725985209855582s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.3725985209855582s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.3725985209855582s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(619.6831615292248 1194.849445456066)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;386.8663230584496 1780.6988909121321"
            keyTimes="0;1"
            dur="5s"
            begin="-4.835459875558293s"
          ></animateTransform>

          <g transform="rotate(111.67289559431802)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.835459875558293s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.835459875558293s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.835459875558293s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1353.5198249583916 1101.7261736021665)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1854.5396499167832 1594.452347204333"
            keyTimes="0;1"
            dur="5s"
            begin="-1.7242912996032256s"
          ></animateTransform>

          <g transform="rotate(44.52182933288902)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.7242912996032256s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.7242912996032256s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.7242912996032256s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(19.61405350404675 738.8841830070464)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-813.2718929919065 868.768366014093"
            keyTimes="0;1"
            dur="5s"
            begin="-0.19120382603836084s"
          ></animateTransform>

          <g transform="rotate(171.1364148830561)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.19120382603836084s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.19120382603836084s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.19120382603836084s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1207.8315206581728 1162.576796038591)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1563.1630413163457 1716.153592077182"
            keyTimes="0;1"
            dur="5s"
            begin="-1.1054801554436189s"
          ></animateTransform>

          <g transform="rotate(57.30428538188447)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1054801554436189s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1054801554436189s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.1054801554436189s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1422.3381479335533 1061.9579997529179)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1992.1762958671065 1514.9159995058358"
            keyTimes="0;1"
            dur="5s"
            begin="-0.06120238664260813s"
          ></animateTransform>

          <g transform="rotate(38.480797670402)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.06120238664260813s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.06120238664260813s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.06120238664260813s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1214.636463316062 57.678223938464726)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1576.7729266321237 -493.64355212307055"
            keyTimes="0;1"
            dur="5s"
            begin="-4.69372907825514s"
          ></animateTransform>

          <g transform="rotate(123.29896519298829)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.69372907825514s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.69372907825514s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.69372907825514s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(273.16766368493336 162.23038155383676)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-306.1646726301333 -284.53923689232647"
            keyTimes="0;1"
            dur="5s"
            begin="-0.6863230622691308s"
          ></animateTransform>

          <g transform="rotate(37.6386975815976)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.6863230622691308s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.6863230622691308s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.6863230622691308s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(440.8427299542972 1142.292283681139)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;29.185459908594453 1675.5845673622782"
            keyTimes="0;1"
            dur="5s"
            begin="-3.2940389481593693s"
          ></animateTransform>

          <g transform="rotate(127.6651533779779)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.2940389481593693s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.2940389481593693s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.2940389481593693s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(93.97319106924874 331.0438574151484)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-664.5536178615025 53.08771483029682"
            keyTimes="0;1"
            dur="5s"
            begin="-4.978647582015222s"
          ></animateTransform>

          <g transform="rotate(20.12495978970308)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.978647582015222s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.978647582015222s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.978647582015222s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(188.12402384630605 990.6102851361122)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-476.2519523073879 1372.2205702722244"
            keyTimes="0;1"
            dur="5s"
            begin="-2.498577520598635s"
          ></animateTransform>

          <g transform="rotate(150.12741222741008)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.498577520598635s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.498577520598635s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.498577520598635s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(379.61764841012257 1115.7184224140788)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-93.26470317975486 1622.4368448281575"
            keyTimes="0;1"
            dur="5s"
            begin="-0.15640308603411146s"
          ></animateTransform>

          <g transform="rotate(133.0217516393063)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.15640308603411146s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.15640308603411146s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.15640308603411146s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1.4701514628322911 644.7502282480981)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-849.5596970743354 680.5004564961962"
            keyTimes="0;1"
            dur="5s"
            begin="-0.8518548465738418s"
          ></animateTransform>

          <g transform="rotate(177.59452200112884)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8518548465738418s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8518548465738418s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8518548465738418s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(383.6987720177139 100.3500233226315)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-85.10245596457219 -408.299953354737"
            keyTimes="0;1"
            dur="5s"
            begin="-3.419689539919497s"
          ></animateTransform>

          <g transform="rotate(47.334542605089624)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.419689539919497s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.419689539919497s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.419689539919497s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1299.9798619158764 90.64233365131486)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1747.4597238317529 -427.7153326973703"
            keyTimes="0;1"
            dur="5s"
            begin="-3.1518430235437203s"
          ></animateTransform>

          <g transform="rotate(130.80288354834775)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.1518430235437203s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.1518430235437203s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.1518430235437203s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(785.2387252838608 1.898480611547484)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;717.9774505677217 -605.203038776905"
            keyTimes="0;1"
            dur="5s"
            begin="-0.5704280110583548s"
          ></animateTransform>

          <g transform="rotate(83.67793646398582)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5704280110583548s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5704280110583548s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5704280110583548s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(215.09455921021402 204.59457718209507)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-422.31088157957197 -199.81084563580987"
            keyTimes="0;1"
            dur="5s"
            begin="-3.560712610188559s"
          ></animateTransform>

          <g transform="rotate(32.393311355117646)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.560712610188559s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.560712610188559s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.560712610188559s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1703.301354320178 570.5744537296138)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2554.102708640356 532.1489074592275"
            keyTimes="0;1"
            dur="5s"
            begin="-0.47050025013291474s"
          ></animateTransform>

          <g transform="rotate(177.41405381846198)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.47050025013291474s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.47050025013291474s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.47050025013291474s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(210.159262445609 1009.4005221599143)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-432.181475108782 1409.8010443198286"
            keyTimes="0;1"
            dur="5s"
            begin="-4.083312458181242s"
          ></animateTransform>

          <g transform="rotate(148.0628151344813)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.083312458181242s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.083312458181242s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.083312458181242s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1040.2102219970657 1203.0535972552918)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1227.9204439941313 1797.1071945105837"
            keyTimes="0;1"
            dur="5s"
            begin="-4.056569689835845s"
          ></animateTransform>

          <g transform="rotate(72.46440343194007)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.056569689835845s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.056569689835845s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.056569689835845s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1670.3578836241159 780.8422216147825)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2488.2157672482317 952.684443229565"
            keyTimes="0;1"
            dur="5s"
            begin="-0.08408803459087344s"
          ></animateTransform>

          <g transform="rotate(11.865956331233576)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.08408803459087344s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.08408803459087344s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.08408803459087344s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1446.6294888517273 1045.7400646346084)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2040.7589777034548 1482.4801292692168"
            keyTimes="0;1"
            dur="5s"
            begin="-2.206668797576569s"
          ></animateTransform>

          <g transform="rotate(36.31932306393177)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.206668797576569s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.206668797576569s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.206668797576569s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(978.7693766041837 6.717325250285512)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1105.0387532083673 -595.565349499429"
            keyTimes="0;1"
            dur="5s"
            begin="-1.92007250684597s"
          ></animateTransform>

          <g transform="rotate(101.84064541607327)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.92007250684597s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.92007250684597s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.92007250684597s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(904.0379419165706 1216.8860901956368)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;955.5758838331412 1824.7721803912737"
            keyTimes="0;1"
            dur="5s"
            begin="-0.5801924005605741s"
          ></animateTransform>

          <g transform="rotate(85.15392477123576)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5801924005605741s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5801924005605741s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5801924005605741s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(729.5600870301035 1211.6340953884842)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;606.6201740602071 1814.2681907769681"
            keyTimes="0;1"
            dur="5s"
            begin="-1.9852836451468936s"
          ></animateTransform>

          <g transform="rotate(101.53036383047102)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.9852836451468936s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.9852836451468936s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.9852836451468936s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1226.8704072102557 1156.135712262283)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1601.2408144205115 1703.271424524566"
            keyTimes="0;1"
            dur="5s"
            begin="-3.027652315971258s"
          ></animateTransform>

          <g transform="rotate(55.61862862780636)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.027652315971258s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.027652315971258s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.027652315971258s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(725.5973664167576 1211.2147488172025)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;598.6947328335152 1813.429497634405"
            keyTimes="0;1"
            dur="5s"
            begin="-0.11656018714631289s"
          ></animateTransform>

          <g transform="rotate(101.89964301696877)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.11656018714631289s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.11656018714631289s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.11656018714631289s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(353.86339997089664 115.04162052192413)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-144.77320005820673 -378.91675895615174"
            keyTimes="0;1"
            dur="5s"
            begin="-0.7127052788798127s"
          ></animateTransform>

          <g transform="rotate(44.72996004005129)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7127052788798127s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7127052788798127s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.7127052788798127s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(103.96508769330626 317.5479809776045)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-644.5698246133875 26.095961955208963"
            keyTimes="0;1"
            dur="5s"
            begin="-4.715816293800703s"
          ></animateTransform>

          <g transform="rotate(21.274112377877547)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.715816293800703s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.715816293800703s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.715816293800703s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(219.02197152835697 201.45746647089408)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-414.45605694328606 -206.08506705821185"
            keyTimes="0;1"
            dur="5s"
            begin="-1.5862853496361384s"
          ></animateTransform>

          <g transform="rotate(32.75484749889603)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5862853496361384s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5862853496361384s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.5862853496361384s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(801.6645627405592 1.083722865736263)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;750.8291254811184 -606.8325542685275"
            keyTimes="0;1"
            dur="5s"
            begin="-1.4740454891836607s"
          ></animateTransform>

          <g transform="rotate(85.21990883208075)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4740454891836607s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4740454891836607s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.4740454891836607s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(282.2388359265765 1061.6862383329817)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-288.02232814684703 1514.3724766659636"
            keyTimes="0;1"
            dur="5s"
            begin="-2.615902191986925s"
          ></animateTransform>

          <g transform="rotate(141.55665708129695)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.615902191986925s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.615902191986925s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.615902191986925s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1686.085451533852 736.573562846713)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2519.670903067704 864.1471256934261"
            keyTimes="0;1"
            dur="5s"
            begin="-3.1342337552418864s"
          ></animateTransform>

          <g transform="rotate(8.701146053319231)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.1342337552418864s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.1342337552418864s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.1342337552418864s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(163.3995210904094 250.46280794395062)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-525.7009578191812 -108.07438411209876"
            keyTimes="0;1"
            dur="5s"
            begin="-0.2789730133422219s"
          ></animateTransform>

          <g transform="rotate(27.48784320367864)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.2789730133422219s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.2789730133422219s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.2789730133422219s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(267.4729921407369 1051.9664960394307)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-317.5540157185262 1494.9329920788612"
            keyTimes="0;1"
            dur="5s"
            begin="-1.0755655003067233s"
          ></animateTransform>

          <g transform="rotate(142.8679876851703)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.0755655003067233s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.0755655003067233s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.0755655003067233s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1554.3749882708414 954.660179792741)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2256.249976541683 1300.320359585482"
            keyTimes="0;1"
            dur="5s"
            begin="-3.0094534676888127s"
          ></animateTransform>

          <g transform="rotate(26.21937561409538)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.0094534676888127s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.0094534676888127s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.0094534676888127s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(359.10085900130576 1105.635070952633)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-134.29828199738847 1602.270141905266"
            keyTimes="0;1"
            dur="5s"
            begin="-2.731334685448281s"
          ></animateTransform>

          <g transform="rotate(134.81272923027092)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.731334685448281s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.731334685448281s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.731334685448281s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(696.8946914197423 1207.7691742643437)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;541.2893828394847 1806.5383485286875"
            keyTimes="0;1"
            dur="5s"
            begin="-3.8587541075674245s"
          ></animateTransform>

          <g transform="rotate(104.56752270053036)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.8587541075674245s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.8587541075674245s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.8587541075674245s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(317.36709735678176 134.93144513131028)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-217.7658052864365 -339.13710973737943"
            keyTimes="0;1"
            dur="5s"
            begin="-3.910993154143132s"
          ></animateTransform>

          <g transform="rotate(41.53739209084953)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.910993154143132s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.910993154143132s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-3.910993154143132s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1352.634384017202 115.81497866674346)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;1852.7687680344038 -377.3700426665131"
            keyTimes="0;1"
            dur="5s"
            begin="-0.07631374778985678s"
          ></animateTransform>

          <g transform="rotate(135.4008405090604)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.07631374778985678s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.07631374778985678s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.07631374778985678s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(838.0917560049144 0.0869864581135289)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;823.6835120098287 -608.8260270837729"
            keyTimes="0;1"
            dur="5s"
            begin="-2.1386637435018665s"
          ></animateTransform>

          <g transform="rotate(88.64450663331883)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.1386637435018665s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.1386637435018665s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.1386637435018665s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1700.4402058692967 546.096298019244)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2548.3804117385935 483.19259603848815"
            keyTimes="0;1"
            dur="5s"
            begin="-0.5117251635675035s"
          ></animateTransform>

          <g transform="rotate(175.75733422363996)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5117251635675035s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5117251635675035s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5117251635675035s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(710.0031058025487 1209.4320855505075)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;567.5062116050974 1809.8641711010152"
            keyTimes="0;1"
            dur="5s"
            begin="-2.495065696319423s"
          ></animateTransform>

          <g transform="rotate(103.35066791933863)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.495065696319423s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.495065696319423s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.495065696319423s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(107.57786732990394 312.86194554837596)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-637.3442653401921 16.723891096751913"
            keyTimes="0;1"
            dur="5s"
            begin="-2.334097272482789s"
          ></animateTransform>

          <g transform="rotate(21.679913507689015)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.334097272482789s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.334097272482789s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.334097272482789s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(18.006089499712402 484.49426263417007)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-816.4878210005752 359.98852526834014"
            keyTimes="0;1"
            dur="5s"
            begin="-2.8353728606988957s"
          ></animateTransform>

          <g transform="rotate(8.485881716504506)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.8353728606988957s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.8353728606988957s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-2.8353728606988957s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(396.26533009179974 94.55168728732656)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-59.96933981640052 -419.8966254253469"
            keyTimes="0;1"
            dur="5s"
            begin="-0.8055056000597127s"
          ></animateTransform>

          <g transform="rotate(48.43202259701429)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffee">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8055056000597127s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8055056000597127s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.8055056000597127s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1699.8473991638796 542.1438871321461)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2547.194798327759 475.2877742642921"
            keyTimes="0;1"
            dur="5s"
            begin="-0.40768557499920144s"
          ></animateTransform>

          <g transform="rotate(175.4886816654753)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.40768557499920144s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.40768557499920144s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.40768557499920144s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(191.1627284334927 993.2944496671537)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-470.1745431330146 1377.5888993343074"
            keyTimes="0;1"
            dur="5s"
            begin="-1.8472741001572968s"
          ></animateTransform>

          <g transform="rotate(149.83968832249238)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.8472741001572968s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.8472741001572968s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-1.8472741001572968s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(146.45785226473072 267.6982530316859)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-559.5842954705386 -73.60349393662818"
            keyTimes="0;1"
            dur="5s"
            begin="-4.30703062530668s"
          ></animateTransform>

          <g transform="rotate(25.799186772650014)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffff">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.30703062530668s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.30703062530668s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.30703062530668s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1682.0009148436964 468.4951552434467)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2511.5018296873927 327.99031048689335"
            keyTimes="0;1"
            dur="5s"
            begin="-0.5438810671449723s"
          ></animateTransform>

          <g transform="rotate(170.38621658226458)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5438810671449723s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5438810671449723s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.5438810671449723s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(384.0581754104044 100.18107298375548)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;-84.38364917919125 -408.63785403248903"
            keyTimes="0;1"
            dur="5s"
            begin="-0.05397629251302938s"
          ></animateTransform>

          <g transform="rotate(47.3659217939727)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffffdd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.05397629251302938s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.05397629251302938s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-0.05397629251302938s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
        <g transform="translate(1702.5462922227741 655.1725424549073)">
          <animateTransform
            attributeName="transform"
            type="translate"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="1 0 1 0"
            values="852.5 609;2552.5925844455483 701.3450849098147"
            keyTimes="0;1"
            dur="5s"
            begin="-4.24101710426719s"
          ></animateTransform>

          <g transform="rotate(3.10911849173825)">
            <ellipse cx="0" cy="0" rx="0" ry="0" fill="#ffeedd">
              <animate
                attributeName="opacity"
                values="0;0;1"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.24101710426719s"
                calcMode="spline"
                keySplines="1 0 1 0;0.5 0 0.5 1"
              ></animate>
              <animate
                attributeName="ry"
                values="0;3"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.24101710426719s"
                calcMode="spline"
                keySplines="1 0 1 0"
              ></animate>
              <animate
                attributeName="rx"
                values="0;12.5"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="5s"
                begin="-4.24101710426719s"
                calcMode="spline"
                keySplines="0.95 0 0.95 0.05"
              ></animate>
            </ellipse>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default InterstellarAnimation;
