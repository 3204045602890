import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
  styles: {
    global: (props: any) => ({
      body: {
        bg: mode("#F4EEE0", "#393646")(props),
        fontWeight: 500,
      },
      ":root": {
        "--chakra-colors-primary-700": mode("#F4EEE0", "#393646")(props),
        "--chakra-colors-primary-600": mode("#F4EEE0", "#4F4557")(props),
        "--chakra-colors-primary-500": mode("#F4EEE0", "#6D5D6E")(props),
        "--chakra-colors-primary-400": mode("#F4EEE0", "#49455a")(props),
      },
    }),
  },
  colors: {
    primary: {
      700: "#393646",
      600: "#4F4557",
      500: "#6D5D6E",
      400: "#49455a",
    },
    success: {
      500: "#21bf73",
    },
    danger: {
      500: "#FA4243",
    },
  },
  fonts: {
    heading: `'Gilroy', sans-serif`,
    body: `'Gilroy', sans-serif`,
  },
  initialColorMode: "dark",
  useSystemColorMode: false,
});

export default theme;
