import { Box, Link, Stack, Text } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { GrTwitter, GrLinkedin, GrGithub } from "react-icons/gr";

interface LinkButtonProps {
  title: string;
  icon: ReactElement;
  url: string;
}

const LinkButton = (props: LinkButtonProps) => {
  return (
    <Link href={props.url} isExternal>
      <Box
        borderWidth={`2px`}
        borderColor={`gray.400`}
        borderStyle={`solid`}
        px={4}
        py={2}
        textTransform={`uppercase`}
        fontWeight={700}
        fontSize={["md", "lg", "xl"]}
      >
        <Stack direction={`row`} alignItems={`center`}>
          <Text>{props.title}</Text>
          {props.icon}
        </Stack>
      </Box>
    </Link>
  );
};

export const OutreachButtons: React.FC = () => {
  return (
    <Stack direction="row" spacing={4} mb={6}>
      <LinkButton
        title={`Github`}
        icon={<GrGithub />}
        url={`https://github.com/humzakhan`}
      />
      <LinkButton
        title={`Twitter`}
        icon={<GrTwitter />}
        url={`https://twitter.com/humzaio`}
      />
      <LinkButton
        title={`LinkedIn`}
        icon={<GrLinkedin />}
        url={`https://www.linkedin.com/in/humzakhaan/`}
      />
    </Stack>
  );
};
