import { Container } from "@chakra-ui/react";
import React from "react";
import { Feed, Introduction } from "../sections";
import { Footer, Navbar, MetaTags, OutreachButtons } from "../components";

export const Homepage: React.FC = () => {
  return (
    <div>
      <MetaTags />
      <Navbar />
      <Introduction />
      <Container maxW="container.xl" mx="auto" w="100%" centerContent>
        <OutreachButtons />
        <Feed />
      </Container>
      <Footer />
    </div>
  );
};
